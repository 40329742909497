









import {
  Component, Emit, VModel, Vue,
} from 'vue-property-decorator';

@Component({})
export default class SurveyCheckBox extends Vue {

  @VModel({ default: false })
  private checked!: boolean;

  @Emit()
  private checkStatusChanged() {
    return this.checked;
  }
}
