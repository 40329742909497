









import {
  Component, Vue, Prop, Emit,
} from 'vue-property-decorator';

@Component({})
export default class SurveyRadioButton extends Vue {

  @Prop({ required: true })
  private inputName!:string;

  private readonly checked: string | null = null;

  @Emit()
  private selected() {
    // empty, emits event
  }

  private changed() {
    if (this.checked !== null && this.checked !== '') {
      this.selected();
    }
  }

}
