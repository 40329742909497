










































































































































































import { AxiosError } from 'axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  QuestionType,
  ScaleSubQuestionAnswer,
  SurveyAnswerRestDTO,
  SurveyQuestionAnswerRestDTO,
  SurveyQuestionRestDTO,
  SurveyQuestionRestDTOChoiceTypeEnum,
  SurveyQuestionRestDTOScaleTypeEnum,
  SurveyRestDTO,
  SurveyResultRestDTO,
  SurveySectionRestDTO,
} from '@/apis/surveyapi';
import ButtonType from '@/assets/buttonTypes';
import Header from '@/assets/headers';
import MessageBoxType from '@/assets/messageBoxTypes';
import Authservice from '@/assets/services/Authservice';
import DateService from '@/assets/services/DateService';
import Services from '@/assets/services/Services';
import LocalStorageKeys from '@/assets/types/localstorageKeys';
import BackLinkWithArrow from '@/components/partials/BackLinkWithArrow.vue';
import InputWithCounter from '@/components/partials/InputWithCounter.vue';
import LinkWithArrow from '@/components/partials/LinkWithArrow.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import MessageBox from '@/components/partials/MessageBox.vue';
import Page from '@/components/partials/Page.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import TextHeader from '@/components/partials/TextHeader.vue';
import ComboboxQuestion from '@/components/surveys/ComboboxQuestion.vue';
import MultipleChoiceQuestion from '@/components/surveys/editor/MultipleChoiceQuestion.vue';
import OneChoiceQuestion from '@/components/surveys/editor/OneChoiceQuestion.vue';
import QuestionSlider from '@/components/surveys/QuestionSlider.vue';

export interface SubmissionValidationError {
  code: 'ANSWER_TEXT_EMPTY' | 'ANSWER_TEXT_TOO_LONG' | 'ANSWER_NOT_PRESENT' | 'ANSWER_REQUIRED_QUESTION_NOT_ANSWERED' | 'REQUIRED_ANSWERS_MISSING'
  location: 'GLOBAL' | 'SECTION' | 'QUESTION' | 'SUB_QUESTION';
  sectionPosition: number;
  position: number;
  subPosition: number;
}

@Component({
  components: {
    MessageBox,
    LoadingAnimation,
    ComboboxQuestion,
    MultipleChoiceQuestion,
    BackLinkWithArrow,
    LinkWithArrow,
    OneChoiceQuestion,
    QuestionSlider,
    RoundedButtonFilled,
    TextHeader,
    InputWithCounter,
    Page,
  },
  metaInfo: {
    title: 'Umfragen │ innovaMo',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Erstellen Sie Umfragen, die Sie entweder direkt in der eigenen Applikation oder auf dem Mobilitätsmarktplatz innovaMo allen Benutzer:innen darbieten.',
      },
    ],
  },
})
export default class Survey extends Vue {
  private Header = Header;
  private ButtonType = ButtonType;
  private QuestionType = QuestionType;
  private ScaleType = SurveyQuestionRestDTOScaleTypeEnum;
  private ChoiceType = SurveyQuestionRestDTOChoiceTypeEnum;
  private MessageBoxType = MessageBoxType;
  private viewedSurveysLocalStorageKey = LocalStorageKeys.VIEWED_SURVEYS;
  private submittedSurveysLocalStorageKey = LocalStorageKeys.SUBMITTED_SURVEYS;

  private initialLoading: boolean = true;
  private showUnexpectedErrorMessage: boolean = false;
  private submittedSuccessfully: boolean = false;
  private showErrorMessage: boolean = false;
  private submitPending: boolean = false;
  private errors: SubmissionValidationError[] = [];
  private allowSubmit: boolean = true;
  private showBanner: boolean = false;

  private openedFromMobileApp: boolean = false;
  private surveyKey: string = '';

  @Prop({ required: true })
  private readonly surveySlug!: string;

  private survey: SurveyRestDTO = { };
  private companyName: string = '';
  private surveyResult: SurveyResultRestDTO = {
    survey_uuid: undefined,
    created_at: undefined,
    answers: [],
  }

  private questionIdToAnswerMap: Record<number, SurveyAnswerRestDTO> = {};
  private questionIdToSubQuestionAnswersMap: Record<number, Record<number, ScaleSubQuestionAnswer>> = {};

  private dateTimeFormatter = DateService.dateTimeFormatterWith2DigitMonthAndWithoutTime;

  private get surveyDate() {
    if (this.survey && this.survey.time_start) {
      return this.dateTimeFormatter.format(new Date(Date.parse(this.survey.time_start as string)));
    }
    return '';
  }

  private get bannerImagePath(): string {
    if (this.survey && this.survey.image && this.survey.image.id) {
      return `${process.env.VUE_APP_SURVEY_SERVICE_IMAGE_URL}${this.survey.image.id}`;
    }
    return '';
  }

  private async mounted() {
    this.openedFromMobileApp = this.$route.query.openedFromMobileApp === 'true';
    this.surveyKey = this.$route.query.surveyKey as string;

    if (this.surveySlug) {
      try {
        const surveyResponse = await Services.surveys.getSurveyBySlug(this.surveySlug);
        this.survey = surveyResponse.data;

        if ((this.survey.isAppSpecific && this.openedFromMobileApp && this.survey.mobileAppKey !== this.surveyKey) || (this.survey.isAppSpecific && !this.openedFromMobileApp)) {
          this.$router.push('/');
        }

        this.survey.sections = this.sortSections(this.survey.sections);

        this.survey.sections.forEach((section) => {
          // eslint-disable-next-line no-param-reassign
          section.questions = this.sortQuestionsByPosition(section.questions);

          section.questions.forEach((question) => {
            if (question.question_type === QuestionType.Scale) {
              // eslint-disable-next-line no-param-reassign
              question.scale_subquestions = this.sortSubQuestionsByPosition(question.scale_subquestions);
            } else if (question.question_type === QuestionType.Choice) {
              // eslint-disable-next-line no-param-reassign
              question.choice_answers = this.sortSubQuestionsByPosition(question.choice_answers);
            }
          });
        });

        this.survey.sections
          .flatMap((s) => s.questions)
          .forEach((q) => {
            if (q && q.id) {
              this.questionIdToAnswerMap[q.id as number] = {
                id: undefined,
                questionType: q.question_type,
                survey_result_id: undefined,
                survey_question_id: q?.id,
                choice_answer_id: undefined,
                choice_multiple_answer_ids: q.question_type === QuestionType.Choice
                                  && q.choice_type === SurveyQuestionRestDTOChoiceTypeEnum.Multiple ? [] : undefined,
                scale_choices: undefined,
                slider_value: q.question_type === QuestionType.Slider ? 50 : undefined,
                freetest_answer: '',
              };

              this.questionIdToSubQuestionAnswersMap[q.id] = this.createSubQuestionAnswerMap(q);
            }
          });

        if (this.survey.isAppSpecific && this.openedFromMobileApp && this.survey.mobileAppKey === this.surveyKey) {
          this.allowSubmit = true;
        } else {
          this.allowSubmit = !this.hasUserSubmittedSurvey();
        }

        const companyInformationResponse = await Services.users.getUserCompanyInformationByUserId(this.survey.developer_id as string);
        this.companyName = companyInformationResponse.data.companyName as string;
        this.surveyResult.survey_uuid = this.survey.uuid;
        await this.increaseImpressionCount();
      } catch (error) {
        // if (!this.openedFromMobileApp) {
        //  this.showUnexpectedErrorMessage = true;
        // }
      }
    }
    this.initialLoading = false;
  }

  private async increaseImpressionCount() {
    const localStorageEntry: string | null = localStorage.getItem(this.viewedSurveysLocalStorageKey);
    const viewedSurveys: string[] = localStorageEntry === null ? [] : JSON.parse(localStorageEntry);

    if (!viewedSurveys.includes(this.survey.uuid as string)) {
      try {
        await Services.surveys.increaseImpressionCount(this.survey.uuid as string);
        viewedSurveys.push(this.survey.uuid as string);
        localStorage.setItem(this.viewedSurveysLocalStorageKey, JSON.stringify(viewedSurveys));
      } catch (e) {
        console.log(e);
      }
    }
  }

  private createSubQuestionAnswerMap(question: SurveyQuestionRestDTO): Record<number, ScaleSubQuestionAnswer> {
    const answerIdToScaleChoiceMap: Record<number, ScaleSubQuestionAnswer> = {};

    // eslint-disable-next-line no-unused-expressions
    question.scale_subquestions?.forEach((s) => {
      answerIdToScaleChoiceMap[s.id as number] = {
        subQuestionId: s.id,
        scale_choice: undefined,
      };
    });

    return answerIdToScaleChoiceMap;
  }

  private login() {
    Authservice.login();
  }

  private sortSections(sections: SurveySectionRestDTO[] | undefined): SurveySectionRestDTO[] {
    if (sections && sections.length > 0) {
      return sections.sort((s1, s2) => ((s1.position as number) < (s2.position as number) ? -1 : 1));
    }

    return [];
  }

  private sortQuestionsByPosition(questions: SurveyQuestionRestDTO[] | undefined): SurveyQuestionRestDTO[] {
    if (questions && questions.length > 0) {
      return questions.sort((q1, q2) => ((q1.position as number) < (q2.position as number) ? -1 : 1));
    }

    return [];
  }

  private sortSubQuestionsByPosition(questions: SurveyQuestionAnswerRestDTO[] | undefined): SurveyQuestionAnswerRestDTO[] {
    if (questions && questions.length > 0) {
      return questions.sort((q1, q2) => ((q1.position as number) < (q2.position as number) ? -1 : 1));
    }

    return [];
  }

  private updateFreeTextAnswer(questionId: number, freeTextAnswer: string) {
    if (this.questionIdToAnswerMap[questionId] && freeTextAnswer) {
      this.questionIdToAnswerMap[questionId].freetest_answer = freeTextAnswer;
    }
  }

  private updateSliderAnswer(questionId: number, sliderValue: number) {
    if (this.questionIdToAnswerMap[questionId]) {
      this.questionIdToAnswerMap[questionId].slider_value = sliderValue;
    }
  }

  private updateComboboxChoiceAnswer(questionId: number, answerId: number) {
    if (this.questionIdToAnswerMap[questionId]) {
      this.questionIdToAnswerMap[questionId].choice_answer_id = answerId < 0 ? undefined : answerId;
    }
  }

  private async submitSurvey() {
    try {
      this.submitPending = true;
      Object.keys(this.questionIdToSubQuestionAnswersMap).forEach((questionId) => {
        const id = parseInt(questionId, 10);
        this.questionIdToAnswerMap[id].scale_choices = Object.values(this.questionIdToSubQuestionAnswersMap[id]);
      });
      this.surveyResult.answers = Object.values(this.questionIdToAnswerMap);
      if (this.survey.isAppSpecific && this.openedFromMobileApp && this.survey.mobileAppKey === this.surveyKey) {
        await Services.surveys.submitSurveyFromMobileApp(this.survey.uuid as string, this.surveyResult);
      } else {
        await Services.surveys.submitSurvey(this.survey.uuid as string, this.surveyResult);
        this.addSubmittedSurveyToLocalStorage();
      }

      this.submittedSuccessfully = true;
      this.errors = [];
      this.submitPending = false;
      this.showErrorMessage = false;
      setTimeout(() => this.$router.push('/surveys'), 5000);
    } catch (err: unknown) {
      const error = err as AxiosError;
      if (error.response && error.response.data.errors && error.response.data.errors.length > 0) {
        this.errors = error.response.data.errors.map((e: any) => ({
          code: e.code,
          location: e.location,
          position: e.position,
          subPosition: e.subPosition,
        }));
      }
      this.submitPending = false;
      this.submittedSuccessfully = false;
      this.showErrorMessage = true;
      setTimeout(() => this.showErrorMessage = false, 5000);
    }
  }

  private hasUserSubmittedSurvey(): boolean {
    const userIdToSubmittedSurveysMap = this.getSubmittedSurveysFromLocalStorage();
    const userId: string = this.$store.state.isLoggedIn ? this.$store.state.userId : 'guest';
    const submittedSurveysOfUser = (userIdToSubmittedSurveysMap.has(userId) ? userIdToSubmittedSurveysMap.get(userId) : []) as string[];
    return submittedSurveysOfUser.includes(this.survey.uuid as string);
  }

  private addSubmittedSurveyToLocalStorage() {

    const userIdToSubmittedSurveysMap = this.getSubmittedSurveysFromLocalStorage();
    const userId: string = this.$store.state.isLoggedIn ? this.$store.state.userId : 'guest';
    const submittedSurveysOfUser = (userIdToSubmittedSurveysMap.has(userId) ? userIdToSubmittedSurveysMap.get(userId) : []) as string[];
    submittedSurveysOfUser.push(this.survey.uuid as string);
    userIdToSubmittedSurveysMap.set(userId, submittedSurveysOfUser);

    localStorage.setItem(this.submittedSurveysLocalStorageKey, JSON.stringify(Array.from(userIdToSubmittedSurveysMap.entries())));
  }

  private getSubmittedSurveysFromLocalStorage(): Map<string, string[]> {
    const localStorageEntry: string | null = localStorage.getItem(this.submittedSurveysLocalStorageKey);
    return localStorageEntry == null ? new Map<string, string[]>() : new Map(JSON.parse(localStorageEntry));
  }

  private filterErrors(question: SurveyQuestionRestDTO): SubmissionValidationError[] {
    return this.errors.filter((e) => {
      console.log('questionId', question.id, 'e.position', e.position);
      console.log('questionId === e.position', e.position === question.id);
      console.log('e.location === \'QUESTION\'', e.location === 'QUESTION');
      console.log();
      return e.location === 'QUESTION' && e.position === question.id;
    });
  }
}
