







































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import { SubmissionValidationError } from '@/views/surveys/Survey.vue';
import { SurveyQuestionAnswerRestDTO } from '@/apis/surveyapi';
import SurveyRadioButton from '@/components/surveys/SurveyRadioButton.vue';

@Component({
  components: {
    SurveyRadioButton,
  },
})

export default class OneChoiceQuestion extends Vue {

    @VModel({ default: 0 })
    private choiceIndex!: number;

    @Prop({ required: true })
    private questionText!:string;

    @Prop({ default: false })
    private withNumbers!:boolean;

    @Prop({ default: false })
    private withSmileys!:boolean;

    @Prop({ default: false })
    private yesOrNo!:boolean;

    @Prop({ default: 5 })
    private numberOfChoices!:number;

    @Prop({ required: true })
    private questionNameSpace!:string;

    @Prop({ default: false })
    private isQuestionRequired!:boolean;

    @Prop({ default: false })
    private questionInColumnWithTextRight!:boolean;

    @Prop({ default: () => ([]) })
    private errors!: SubmissionValidationError[];

    @Prop({})
    private questionTextForColumnsWithTextRight!: SurveyQuestionAnswerRestDTO[];

    @Prop({ type: Boolean, default: false })
    private hasFreeTextAnswer!: boolean;

    @Prop({ default: '' })
    private freeTextHint!: string;

    private freeTextAnswerInput: string = '';

    private freeTextAnswerChecked: boolean = false;

    private get freeTextInputDisabled(): boolean {
      return !this.freeTextAnswerChecked;
    }

    @Emit()
    private freeTextAnswerChanged() {
      return this.freeTextAnswerInput;
    }

    private get getQuestionText() {
      if (this.isQuestionRequired) {
        return `* ${this.questionText}`;
      }
      return this.questionText;
    }

    private get classesForRadioButtonGeneration() {
      let classes = '';
      if (this.yesOrNo) {
        classes += 'flex-col ';
      } else if (this.questionInColumnWithTextRight) {
        classes += 'flex-row ';
      }

      if (this.questionInColumnWithTextRight) {
        if (this.numberOfChoices === 2) {
          classes += 'grid-rows-2 ';
        } else if (this.numberOfChoices === 3) {
          classes += 'grid-rows-3 ';
        } else if (this.numberOfChoices === 4) {
          classes += 'grid-rows-4 ';
        } else if (this.numberOfChoices === 5) {
          classes += 'grid-rows-5 ';
        } else if (this.numberOfChoices === 10) {
          classes += 'grid-rows-10 ';
        }
      } else if (this.numberOfChoices === 2) {
        classes += 'grid-cols-2 ';
      } else if (this.numberOfChoices === 3) {
        classes += 'grid-cols-3 ';
      } else if (this.numberOfChoices === 4) {
        classes += 'grid-cols-4 ';
      } else if (this.numberOfChoices === 5) {
        classes += 'grid-cols-5 ';
      } else if (this.numberOfChoices === 10) {
        classes += 'grid-cols-10 ';
      }

      return classes;
    }

    private selectOption(index: number) {
      console.log(index);
      this.choiceIndex = index;
      this.freeTextAnswerChecked = index === -1;
    }

}
