








import { Component, VModel, Vue } from 'vue-property-decorator';

@Component({})
export default class InputWithCounter extends Vue {

  @VModel({ required: true })
  private vmodel!: string;

  private inputText: string = '';

  private inputChange(event: InputEvent) {
    this.inputText = this.inputText.substr(0, 500);
    this.vmodel = this.inputText;
  }

  private paste(event: ClipboardEvent) {
    this.inputText = this.inputText.substr(0, 500);
    this.vmodel = this.inputText;
  }

}
