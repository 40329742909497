
































import { Component, Vue } from 'vue-property-decorator';
import CustomSelect, { Option } from '@/components/partials/CustomSelect.vue';
import Header from '@/assets/headers';
import TextHeader from '@/components/partials/TextHeader.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import { SurveyOverviewRestDTO } from '@/apis/surveyapi';
import Services from '@/assets/services/Services';
import Sort from '@/assets/types/sortingsurveys';
import SearchBar from '@/components/partials/SearchBar.vue';
import LinkWithArrow from '@/components/partials/LinkWithArrow.vue';
import SurveyCard from '@/components/surveys/SurveyCard.vue';
import RoundedButtonOutlined from '@/components/partials/RoundedButtonOutlined.vue';
import ButtonType from '@/assets/buttonTypes';
import Page from '@/components/partials/Page.vue';
import UnexpectedErrorMessage from '@/components/partials/UnexpectedErrorMessage.vue';

@Component({
  components: {
    CustomSelect,
    TextHeader,
    LoadingAnimation,
    SearchBar,
    LinkWithArrow,
    SurveyCard,
    RoundedButtonOutlined,
    Page,
    UnexpectedErrorMessage,
  },
  metaInfo: {
    title: 'Umfragen │ innovaMo',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Erstellen Sie Umfragen, die Sie entweder direkt in der eigenen Applikation oder auf dem Mobilitätsmarktplatz innovaMo allen Benutzer:innen darbieten.',
      },
      {
        vmid: 'preview-image',
        property: 'og:image',
        content: 'https://www.innovamo.de/img/seo/digital_network.png',
      },
      {
        vmid: 'preview-image-twitter',
        property: 'twitter:image',
        content: 'https://www.innovamo.de/img/seo/digital_network.png',
      },
    ],
  },
})
export default class Surveys extends Vue {
    private ButtonType = ButtonType;
    private Sort = Sort;
    private Header = Header;

    private surveys: SurveyOverviewRestDTO[] = [];

    private loading: boolean = true;
    private showUnexpectedErrorMessage: boolean = false;
    private sortValue: Option = {
      name: 'Erscheinungsdatum (ab)',
      value: Sort.START_DATE_DESCENDING,
    };

    private sortOptions: Option[] = [
      {
        name: 'Erscheinungsdatum (ab)',
        value: Sort.START_DATE_DESCENDING,
      },
      {
        name: 'Erscheinungsdatum (auf)',
        value: Sort.START_DATE_ASCENDING,
      },
      {
        name: 'Name (ab)',
        value: Sort.NAME_DESCENDING,
      },
      {
        name: 'Name (auf)',
        value: Sort.NAME_ASCENDING,
      },
    ];
    private searchTerm: string = '';
    private currentPage: number = 0;
    private totalPages: number = 0;
    private itemsPerPage: number = 10;
    private totalNumberOfItems: number = 0;

    private getBannerImagePath(index: number): string {
      const survey = this.surveys[index];
      if (survey && survey.image && survey.image.id) {
        return `${process.env.VUE_APP_SURVEY_SERVICE_IMAGE_URL}${survey.image.id}`;
      }

      return require('@/assets/images/logo/logo_innovamo_padded.png');
    }

    private get showMoreButtonEnabled(): boolean {
      return this.currentPage < this.totalPages - 1;
    }

    private userIdToDeveloperNameMap: Map<string, string> = new Map<string, string>();

    private async mounted() {
      try {
        this.surveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);
        this.surveys.forEach((s) => this.userIdToDeveloperNameMap.set(s.developer_id as string, ''));

        const promises = [];

        // eslint-disable-next-line no-restricted-syntax
        for (const key of Array.from(this.userIdToDeveloperNameMap.keys())) {
          promises.push(this.loadCompanyNameIfNotExists(key as string));
        }

        await Promise.all(promises);

        this.loading = false;
      } catch (e) {
        this.surveys = [];
        this.showUnexpectedErrorMessage = true;
      } finally {
        this.loading = false;
      }
    }

    private async loadCompanyNameIfNotExists(developerId: string) {
      if (developerId && developerId !== '') {
        const companyName: string = await this.getCompanyName(developerId);
        this.userIdToDeveloperNameMap.set(developerId as string, companyName);
      }
    }

    private async getCompanyName(developerId: string) {

      let companyName: string = '';

      try {
        const companyInformationResponse = await Services.users.getUserCompanyInformationByUserId(developerId);
        companyName = companyInformationResponse.data.companyName as string;
      } catch (e) {
        companyName = '';
      }

      return companyName;
    }

    private async searchButtonClicked(searchTerm: string) {
      this.loading = true;
      this.currentPage = 0;
      this.searchTerm = searchTerm;
      this.surveys = await this.searchSurveys(this.currentPage, searchTerm, this.sortValue);
      this.loading = false;
    }

    private sortValueChanged() {
      this.searchButtonClicked(this.searchTerm);
    }

    private async searchSurveys(pageNumber: number = 0, searchTerm: string, sortBy: Option): Promise<SurveyOverviewRestDTO[]> {
      try {
        const response = await Services.surveys.getPublishedSurveys(pageNumber, this.itemsPerPage, searchTerm, sortBy.value.value, sortBy.value.order);
        this.currentPage = response.data.currentPage as number;
        this.totalNumberOfItems = response.data.totalItemsFound as number;
        this.totalPages = response.data.totalPages as number;

        if (searchTerm.trim()) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (this.$matomo) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
            this.$matomo.trackSiteSearch(searchTerm, 'published surveys', response.data.totalItemsFound);
          }
        }
        return response.data.surveys as SurveyOverviewRestDTO[];
      } catch (e) {
        this.showUnexpectedErrorMessage = true;
        return [];
      }
    }

    private async showMore() {
      this.currentPage += 1;
      const additionalSurveys = await this.searchSurveys(this.currentPage, this.searchTerm, this.sortValue);
      this.surveys.push(...additionalSurveys);
    }

}
