import { render, staticRenderFns } from "./ComboboxQuestion.vue?vue&type=template&id=ec41502c&scoped=true&"
import script from "./ComboboxQuestion.vue?vue&type=script&lang=ts&"
export * from "./ComboboxQuestion.vue?vue&type=script&lang=ts&"
import style0 from "./ComboboxQuestion.vue?vue&type=style&index=0&id=ec41502c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec41502c",
  null
  
)

export default component.exports