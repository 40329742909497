

















import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';
import { SubmissionValidationError } from '@/views/surveys/Survey.vue';
import CustomSelect, { Option } from '@/components/partials/CustomSelect.vue';
import { SurveyQuestionAnswerRestDTO } from '@/apis/surveyapi';

@Component({
  components: { CustomSelect },
})
export default class ComboboxQuestion extends Vue {

  @Prop({ required: true })
  private readonly question!: string;

  @Prop({ required: true })
  private readonly answers!: SurveyQuestionAnswerRestDTO[];

  @Prop({ default: false })
  private isQuestionRequired!:boolean;

  @Prop({ default: () => ([]) })
  private errors!: SubmissionValidationError[];

  private selectedValue: Option = {
    name: '',
    value: -1,
  };

  private selectOptions: Option[] = this.answers.map((a) => ({
    name: a.answer_text,
    value: a.id,
  }));

  @Emit()
  private answerChanged() {
    return this.selectedValue.value;
  }
}
