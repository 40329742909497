



































import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';
import { SubmissionValidationError } from '@/views/surveys/Survey.vue';
import { SurveyQuestionAnswerRestDTO } from '@/apis/surveyapi';
import SurveyCheckBox from '@/components/surveys/SurveyCheckBox.vue';

@Component({
  components: {
    SurveyCheckBox,
  },
})
export default class MultipleChoiceQuestion extends Vue {

  @VModel({ default: () => ([]) })
  private selectedChoicesIds!: number[];

  @Prop({ required: true })
  private readonly question!: string;

  @Prop({ required: true })
  private readonly answers!: SurveyQuestionAnswerRestDTO[];

  @Prop({ type: Boolean, default: false })
  private readonly hasFreetextAnswer!: boolean;

  @Prop({ default: '' })
  private readonly freeTextHint!: string;

  @Prop({ default: () => ([]) })
  private errors!: SubmissionValidationError[];

  @Prop({ default: false })
  private isQuestionRequired!:boolean;

  private freeTextAnswerChecked: boolean = false;
  private freeTextAnswerInput: string = '';

  private get freeTextInputDisabled(): boolean {
    return !this.freeTextAnswerChecked;
  }

  private get numberOfAnswers() {
    return this.hasFreetextAnswer ? this.answers.length + 1 : this.answers.length;
  }

  @Emit()
  private freeTextAnswerChanged() {
    return this.freeTextAnswerInput;
  }

  private checkBoxStatusChanged(answerId: number, isChecked: boolean) {
    if (!this.selectedChoicesIds.includes(answerId)) {
      console.log('answer clicked', answerId);
      this.selectedChoicesIds.push(answerId);
      console.log(this.selectedChoicesIds);
    } else {
      console.log('remove choice');
      const newSelection = this.selectedChoicesIds.filter((id) => id !== answerId);
      this.selectedChoicesIds.length = 0;
      this.selectedChoicesIds.push(...newSelection);
      console.log(this.selectedChoicesIds);
    }
  }
}
